import one from '../Assets/01-comprehensive.jpg'
import two from '../Assets/02-Third Party Firee.jpg'
import three from '../Assets/03-Third Party Only.jpg'
import four from '../Assets/04-Fleet.jpg'
import five from '../Assets/05-Motor trade.jpg'
import six from '../Assets/06-ActOnly.webp'
import seven from '../Assets/07-FireandSpecialPerils.jpg'
import eight from '../Assets/08-Burglary.jpg'
import nine from '../Assets/09-AllRisk.jpg'
import ten from '../Assets/10-Combined.jpeg'
import eleven from '../Assets/11-BusinessInterruptions.jpg'
import twelve from '../Assets/12-FidelityGuarantee.jpg'
import thirteen from '../Assets/13-LegalExpenses.jpg'
import fourteen from '../Assets/14-CreditInsurance.webp'
import fifteen from '../Assets/15-MoneyInsurance.jpg'
import sixteen from '../Assets/16-Bond.jpeg'
import seventeen from '../Assets/17-PersonalAccident.jpg'
import eighteen from '../Assets/18-GroupPersonal.webp'
import nineteen from '../Assets/19-Sickness.webp'
import twenty from '../Assets/20-TravelInsurance.jpg'
import twentyone from '../Assets/21-EmployersLiability.jpg'
import twentytwo from '../Assets/22-PublicLiability.jpg'
import twentythree from '../Assets/23-ProductLiability.webp'
import twentyfour from '../Assets/24-ProfessionalIndemnity.jpg'
import twentyfive from '../Assets/25-Directors.webp'
import twentysix from '../Assets/26-Contractors.jpg'
import twentyseven from '../Assets/27-Erection.jpg'
import twentyeight from '../Assets/28-Machinery.jpg'
import twentynine from '../Assets/29-BoilerExplosion.webp'
import thirty from '../Assets/30-Hull.webp'
import thirtyone from '../Assets/31-Cargo.jpg'
import thirtytwo from '../Assets/32-Freight.jpg'
import thirtythree from '../Assets/33-Liability.webp'


export const POLICIES = [
    {
        title: "Comprehensive Cover",
        coverage: "Accidental Damage to insured vehicle, Fire, Theft, Accidental damage to the Third Party’s vehicle, Accidental Damage to property of the Third Party, Death or bodily injury to the Third Party",
        benefits: "Extensive protection for both insured vehicle and third parties",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on vehicle and other factors",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['car', 'motor', 'vehicle', 'death', 'injury', 'accident', 'damage', 'fire', 'theft', 'passengers', 'repairs'],
        key: 1,
        picture: one,
    },
    {
        title: "Third Party Fire & Theft Cover",
        coverage: "Accidental damage to only the Third Party’s vehicle, Accidental damage to property of the Third Party, Death or bodily injury to the Third Party, Fire, Theft",
        benefits: "Covers essential risks for both the insured and third parties",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on vehicle and other factors",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['car', 'motor', 'vehicle', 'death', 'injury', 'accident', 'damage', 'fire', 'theft'],
        key: 2,
        picture: two,
    },
    {
        title: "Third Party Only Cover",
        coverage: "Accidental damage to only the Third Party’s vehicle, Accidental damage to only the Third Party’s property, Death or bodily injury to the Third Party",
        benefits: "Basic coverage for third-party liabilities",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on vehicle and other factors",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['car', 'motor', 'vehicle', 'death', 'injury', 'accident', 'damage'],
        key: 3,
        picture: three,
    },
    {
        title: "Fleet policies",
        coverage: "Insurance of a number of vehicles associated with the insured under one policy",
        benefits: "Convenient coverage for multiple vehicles under a single policy",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on number of vehicles, usage, and other factors",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['cars', 'motor', 'vehicle', 'fleet', 'repair', 'damage', 'accident'],
        key: 4,
        picture: four,
    },
    {
        title: "Motor trade policy",
        coverage: "Designed to meet the insurance needs of those selling, servicing, and repairing vehicles",
        benefits: "Tailored coverage for businesses involved in motor trade activities",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business size, activities, and other factors",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['car', 'motor', 'vehicle', 'repair', 'damage', 'accident'],
        key: 5,
        picture: five,
    },
    {
        title: "Act Only Policy",
        coverage: "Unlimited cover for liability for death or bodily injury to third party including passengers; Cover for damage to third party property caused by an accident involving the vehicle",
        benefits: "Meets minimum legal requirements for using a motor vehicle on public highways",
        requirements: "Mandatory for anyone using a motor vehicle on public highways",
        premium: "Varies based on insurer and vehicle type",
        resources: "https://www.custodianplc.com.ng/car-insurance",
        tag: ['car', 'motor', 'vehicle', 'passengers', 'death', 'injury', 'accident'],
        key: 6,
        picture: six,
    },
    {
        title: "Fire and Special Perils",
        coverage: "Damage to property caused by fire, lightning, or explosion of gas and boilers used for domestic purposes; Can be extended to include special perils such as explosion, storm, flood, burst pipes, malicious damage, riots, and civil commotion",
        benefits: "Basic coverage for fire-related damages with options to extend coverage for additional perils",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on property value, location, and additional coverage selected",
        resources: "Insurance company, brokers, online portals",
        tag: ['property', 'house', 'building', 'fire', 'gas', 'boilers', 'explosion', 'storm', 'flood', 'burst pipes', 'riots'],
        key: 7,
        picture: seven,
      },
      {
        title: "Burglary & Theft",
        coverage: "Provides cover to contents or damage to properties arising out of forcible and violent entry and or exit into the insured premises",
        benefits: "Protection against theft-related losses with specific criteria for burglary to be proven",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on property value, security measures, and other factors",
        resources: "Insurance company, brokers, online portals",
        tag: ['property', 'house', 'building', 'theft', 'steal','accesory', 'accesories', 'jewellery','watches','shoes','clothes', 'phone', 'tv', 'television', 'appliances', 'laptop', 'computer'],
        key: 8,
        picture: eight,
      },
      {
        title: "All Risk",
        coverage: "Covers properties specified against any loss or damage which is not specifically excluded from the policy",
        benefits: "Comprehensive coverage for a wide range of risks, excluding specific exclusions",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on property value, type of property, and level of coverage",
        resources: "Insurance company, brokers, online portals",
        tag: ['property', 'house', 'building', 'phone', 'tv', 'television', 'appliances', 'laptop', 'computer'],
        key: 9,
        picture: nine,
      },
      {
        title: "Combined Household Policies",
        coverage: "Brings together different types of cover into one policy covering the property of the insured, including building, contents, or both",
        benefits: "Convenience and potential cost savings by consolidating multiple coverages into one policy",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on property value, coverage options selected, and other factors",
        resources: "Insurance company, brokers, online portals",
        tag: ['property', 'house', 'building', 'fire'],
        key: 10,
        picture: ten,
    },
    {
        title: "Business Interruptions",
        coverage: "Provides cover against losses arising out of business interruption after major damage to buildings and content from an insured peril",
        benefits: "Protection for business profit against reduction in sales, additional expenses on recovery process, and continuous payment of standing expenses",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business size, nature, and level of coverage",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'profit', 'sales'],
        key: 11,
        picture: eleven,
      },
      {
        title: "Fidelity Guarantee",
        coverage: "Provides cover for a business against loss of money or stock due to the fraud or dishonesty of a staff member",
        benefits: "Protection against financial losses due to fraudulent activities by employees",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business size, nature, and level of coverage",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'fraud', 'staff', 'employee', 'stock'],
        key: 12,
        picture: twelve,
      },
      {
        title: "Legal Expenses",
        coverage: "Cover provided to businesses or individuals to cover the cost of seeking legal advice or pursuing or defending civil actions",
        benefits: "Financial assistance for legal proceedings or advice",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on coverage limits and nature of legal exposure",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'profit', 'legal', 'advice'],
        key: 13,
        picture: thirteen,
      },
      {
        title: "Credit Insurance",
        coverage: "Covers a business against a customer being unable to make payment for goods and services received when the time is due",
        benefits: "Protection against non-payment by customers",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business size, credit risk, and coverage limits",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'credit', 'goods', 'services'],
        key: 14,
        picture: fourteen,
      },
      {
        title: "Money Insurance",
        coverage: "Covers various forms of money in various locations, including loss of money, damage to safes, and strong rooms",
        benefits: "Protection for financial assets such as cash, cheques, bank drafts, etc., against loss or damage",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on amount of money insured, locations covered, and level of risk",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'profit', 'sales', 'cash', 'cheques', 'bank drafts'],
        key: 15,
        picture: fifteen,
      },
      {
        title: "Bond",
        coverage: "A form of guarantee or Surety given by an insurance company in respect of obligations to a third party on behalf of their insured",
        benefits: "Assurance to third parties regarding the fulfillment of obligations by the insured",
        requirements: "Dependent on type of bond and insurer's terms and conditions",
        premium: "Varies based on type of bond, amount, and duration",
        resources: "Insurance company, brokers, online portals",
        tag: ["pecuniary", 'money', 'business', 'bond'],
        key: 16,
        picture: sixteen,
    },
    {
        title: "Personal Accident",
        coverage: "Provides benefits to individuals in the event of death, permanent disability, loss of eyes or limbs due to an accident",
        benefits: "Financial compensation for accident-related injuries or fatalities",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on coverage limits and individual factors",
        resources: "Insurance company, brokers, online portals",
        tag: ["accident", "personal", "employees",'injury', "incapacitation", "hospital", 'emergencies', 'emergency', 'surgery', 'death', 'disability', 'limbs', 'body parts'],
        key: 17,
        picture: seventeen,
      },
      {
        title: "Group Personal Accident",
        coverage: "Essentially a personal accident cover within a defined group such as employees in a particular company, members of a named society, etc.",
        benefits: "Collective protection for a group of individuals against accident-related injuries or fatalities",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on group size, nature, and level of coverage",
        resources: "Insurance company, brokers, online portals",
        tag: ["accident", "group", "employees",'injury', "incapacitation", "hospital", 'emergencies', 'emergency', 'surgery', 'death', 'disability', 'limbs', 'body parts'],
        key: 18,
        picture: eighteen,
      },
      {
        title: "Sickness & Medical Expenses",
        coverage: "Medical insurance policies provide covers to individuals for medical treatment within and outside a nation, while sickness insurance provides benefits during incapacitation",
        benefits: "Coverage for medical treatment, hospital charges, surgical fees, and related costs due to sickness or medical emergencies",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on coverage limits, age, and medical history",
        resources: "Insurance company, brokers, online portals",
        tag: ["accident", "sickness", "medical", "incapacitation", "hospital", 'emergencies', 'emergency', 'surgery'],
        key: 19,
        picture: nineteen,
      },
      {
        title: "Travel Insurance",
        coverage: "Provides covers to individuals traveling within and outside of the nation, covering personal accident, medical expenses, loss of deposit, personal liability, loss of baggage and personal effects, delayed baggage, travel delay and interruptions, and hospital cash benefits",
        benefits: "Comprehensive coverage for various risks encountered during travel",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on trip duration, destination, coverage options, and individual factors",
        resources: "Insurance company, brokers, online portals",
        tag: ["accident", "travel", "travel delay", "baggage", "interruption"],
        key: 20,
        picture: twenty,
    },
    {
        title: "Employers’ Liability",
        coverage: "Covers employers for any sum which they may become liable to pay to any employee in respect of injury, disease, illness, or death caused during employment",
        benefits: "Compulsory insurance under the Employers Liability Compulsory Insurance Act 1969 (UK)",
        requirements: "Mandatory for employers",
        premium: "Varies based on business size, industry, and number of employees",
        resources: "Insurance company, brokers, legal advisors",
        tag: ["liability", "employers", "employee", "injury", "death", 'illness', 'diseases'],
        key: 21,
        picture: twentyone,
      },
      {
        title: "Public Liability",
        coverage: "Covers the liability of the insured at law to members of the public for injuries suffered or property damage arising out of the operation of the insured's business",
        benefits: "Protection against legal liabilities to third parties",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business activities, size, and level of coverage",
        resources: "Insurance company, brokers, legal advisors",
        tag: ["liability", "public", "injury", "property damage"],
        key: 22,
        picture: twentytwo,
      },
      {
        title: "Product Liability",
        coverage: "Covers the liability of those who sell, supply, and/or manufacture goods that cause injury or damage to others or their property",
        benefits: "Protection for businesses against claims related to defective products",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on business activities, products, and level of coverage",
        resources: "Insurance company, brokers, legal advisors",
        tag: ["liability", "product", "injury", "property damage", 'goods'],
        key: 23,
        picture: twentythree,
      },
      {
        title: "Professional Indemnity",
        coverage: "Covers a professional person or firm against claim for loss due to wrongful advice or failure to carry out professional duties properly",
        benefits: "Financial protection against claims of professional negligence",
        requirements: "Dependent on profession and insurer's terms and conditions",
        premium: "Varies based on profession, experience, and level of coverage",
        resources: "Insurance company, brokers, professional associations",
        tag: ["liability", "professional", "negligence", "wrongful advice"],
        key: 24,
        picture: twentyfour,
      },
      {
        title: "Directors and Officers",
        coverage: "Provides cover for Directors and other officers for acts of negligence in running a company for which they may become liable",
        benefits: "Protection for directors and officers against legal liabilities arising from their management decisions",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on company size, industry, and level of coverage",
        resources: "Insurance company, brokers, legal advisors",
        tag: ["liability", "directors", "officers", "negligence", "management decisions"],
        key: 25,
        picture: twentyfive,
      },
      {
        title: "Contractors All Risks",
        coverage: "Provides property insurance cover on an all risk basis for specified property which includes contract works, materials, construction plants and equipment's, construction machinery, and cost of debris removal; Also provides liability insurance for third party injury or property damage arising out of the constructing work",
        benefits: "Comprehensive coverage for construction projects",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on project value, duration, and risk factors",
        resources: "Insurance company, brokers, construction professionals",
        tag: ["contractors", "risks", "engineering", "insurance", 'equipment', 'machinery'],
        key: 26,
        picture: twentysix,
      },
      {
        title: "Erection All Risks",
        coverage: "Involves the actual construction or making of buildings with components usually made outside of the site, such as bridge construction, construction of transmitters, and large storage installations",
        benefits: "Coverage for risks during erection or construction",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on project complexity, duration, and risk factors",
        resources: "Insurance company, brokers, construction professionals",
        tag: ["erection", "construction", "engineering", "insurance"],
        key: 27,
        picture: twentyseven,
      },
      {
        title: "Machinery Breakdown",
        coverage: "Provides cover on an all risk basis consequent upon unforeseen and sudden physical loss-off or damaged to insured items",
        benefits: "Protection against sudden breakdown of machinery",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on machinery value, age, and risk factors",
        resources: "Insurance company, brokers, machinery suppliers",
        tag: ["machinery", "breakdown", "engineering", "insurance"],
        key: 28,
        picture: twentyeight,
      },
      {
        title: "Boiler Explosion",
        coverage: "Provides cover against loss or damage resulting from explosion or collapse of boiler while working in the ordinary course of the Insured’s business",
        benefits: "Protection for boiler-related damages and liabilities",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on boiler type, size, and usage",
        resources: "Insurance company, brokers, boiler inspectors",
        tag: ["boiler", "explosion", "engineering", "insurance"],
        key: 29,
        picture: twentynine,
      },
      {
        title: "Hull",
        coverage: "Covers certain liabilities in addition to damage to the ship arising from the perils of the sea; Covers properties belonging to the insured such as equipment's, stores, fuel, and safety boats; Also covers general average and salvaged losses",
        benefits: "Protection for shipowners against various risks at sea",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on vessel type, value, and usage",
        resources: "Insurance company, brokers, maritime professionals",
        tag: ["hull", "marine", "insurance", 'ship', 'sea', 'equipment', 'boat'],
        key: 30,
        picture: thirty,
      },
      {
        title: "Cargo",
        coverage: "Often insured on an all-risk basis and can be covered either for the period of the voyage; from warehouse to warehouse or on an open cover basis; Insured in the form of a set of Institutes cargo clauses (ICC)",
        benefits: "Comprehensive coverage for goods during transit",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on cargo value, destination, and coverage options",
        resources: "Insurance company, brokers, shipping agents",
        tag: ["cargo", "marine", "insurance", 'ship', 'sea', 'equipment', 'boat'],
        key: 31,
        picture: thirtyone,
      },
      {
        title: "Freight",
        coverage: "Covers freight charges; Usually insured by the shipowner if it is at their risk or included in the value of the cargo if it is prepaid",
        benefits: "Protection for freight charges during shipping",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on cargo value, shipping route, and terms of sale",
        resources: "Insurance company, brokers, shipping agents",
        tag: ["freight", "marine", "insurance" ,'ship', 'sea', 'boat'],
        key: 32,
        picture: thirtytwo,
      },
      {
        title: "Liability",
        coverage: "Liability risk in marine is usually insured under the P&I (Protection and indemnity Association) clause; Usually a self-insurance scheme run on behalf of shipowners or on a mutual basis",
        benefits: "Protection against third-party liabilities in marine operations",
        requirements: "Dependent on insurer's terms and conditions",
        premium: "Varies based on vessel type, size, and operations",
        resources: "Insurance company, brokers, maritime professionals",
        tag: ["liability", "marine", "insurance", 'ship', 'sea', 'boat'],
        key: 33,
        picture: thirtythree,
      },
];
